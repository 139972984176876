<template>
  <div class="CmsContentsFiledsList">
    <div class="contentsWidth mx-auto mt-4">
      <div class="">
        <p class="title mb-0">申請 - 変更履歴</p>
        <hr class="title">
      </div>

      <div id="serchWrap" class="mt-4">
        <div class="bold">絞り込み条件</div>
        <div class="search_area flex flexCenter">
          <div class="searchShort inlineBlock searchSpace">
            <div>年度</div>
            <div>
              <b-form-select
                v-model="search.year"
                :options="yearList"
                @change="filteringHistoryData">
              </b-form-select>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>助成種別</div>
            <div>
              <b-form-select
                v-model="search.series"
                :options="apptypeList"
                @change="filteringHistoryData">
                <template v-slot:first>
                  <option :value="''">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>申請者名</div>
            <div>
              <b-form-input
                v-model="search.appName"
                placeholder="入力してください。"
                @input="filteringHistoryData"/>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>変更者氏名</div>
            <div>
              <b-form-input
                v-model="search.changerName"
                placeholder="入力してください。"
                @input="filteringHistoryData"/>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>ステータス変更</div>
            <div>
              <b-form-select
                v-model="search.status"
                :options="statusList"
                @change="filteringHistoryData">
                <template v-slot:first>
                  <option :value="''">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
          <div class="searchShort inlineBlock">
            <div>データ変更</div>
            <div>
              <b-form-select
                v-model="search.hasDataChange"
                :options="dataChangeStatusList"
                @change="filteringHistoryData">
                <template v-slot:first>
                  <option :value="''">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
        </div>
      </div>

      <div class="contentsWidth mt-2">
        <div class="flex flex-between my-2">
          <b-pagination
            class="mb-1"
            aria-controls="fieldTable"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <div>
            <div class="inlineBlock mr-2">
              {{nowPageCount}}件 / 全{{totalRows}}件
            </div>
            <div class="inlineBlock">
              <span class="mr-2">表示件数</span>
              <b-form-select
                class="per-page-select"
                v-model="perPage"
                :options="pageOptions"
              />
            </div>
          </div>
        </div>
      </div>

      <div id="tableWrap" class="contentsWidth mt-2">
        <b-table
          show-empty
          striped
          id='fieldTable'
          table-class="cmsAppTable icon-white"
          thead-class="tableHead"
          tbody-tr-class="dataWrap"
          :fields="header"
          :items="historyData"
          :per-page="perPage"
          :current-page="currentPage"

        >
          <template v-slot:cell(appKana)="data">
            {{data.item.appName}}
          </template>
          <template v-slot:cell(changerKana)="data">
            {{data.item.changerName}}
          </template>
          <template v-slot:cell(hasDataChange)="data">
            {{dataChangeStatusList[data.item.hasDataChange]}}
          </template>
          <template v-slot:empty="scope">
            <div class="flex flexCenter">条件に一致するデータがありません。</div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/modules/api';
import CONST_STATUS from '@/constants/appStatus';

export default {
  name: 'CmsApplicationsHistoryList',
  data() {
    return {
      search: {
        year: null,
        series: '',
        appName: null,
        changerName: null,
        status: '',
        hasDataChange: '',
      },
      yearList: [],
      apptypeList: [],
      dataChangeStatusList: CONST_STATUS.CHANGE_DATA_STATUS_TEXT,
      statusList: CONST_STATUS.CMS_APP_STATUS_OPTION_4_LIST,
      status: CONST_STATUS.APP_STATUS,
      header: [
        { key: 'year', label: '年度', sortable: true },
        { key: 'apptype', label: '助成種別', sortable: true },
        { key: 'appKana', label: '申請者名', sortable: true },
        { key: 'changerKana', label: '変更者氏名', sortable: true },
        { key: 'createdAt', label: '変更日時', sortable: true },
        { key: 'changeStatusText', label: 'ステータス変更', sortable: true },
        { key: 'hasDataChange', label: 'データ変更', sortable: true },
      ],
      historyData: [],
      initHistoryData: [],
      perPage: 10,
      pageOptions: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 },
      ],
      currentPage: 1,
      totalRows: 0,
    };
  },
  async created() {
    this.$store.dispatch('page/onLoading', 'CmsTop');
    const promiseFuncs = [api.send('/api/cms/applications/history/list')];
    await this.initFetch(promiseFuncs);
    this.$store.dispatch('page/offLoading', 'CmsTop');
  },
  methods: {
    async initFetch(promiseFuncs) {
      const res = await api.all(promiseFuncs)
        .catch((error) => {
          console.log(error);
          return false;
        });
      if (res !== false) {
        this.initHistoryData = res[0].data.historyData;
        this.yearList = this.setYerSelect(res[0].data.yearList);
        this.search.year = res[0].data.yearList.shift();
        this.apptypeList = this.setAppTypeSelect(res[0].data.appTypeList);
        this.filteringHistoryData();
      }
    },
    dataReset(initData) {
      return JSON.parse(JSON.stringify(initData));
    },
    /**
     * select項目用に整形
     * @param [Array] Years
     * @return [object]
     */
    setYerSelect(Years) {
      return Years.map((year) => {
        return { value: year, text: year };
      });
    },
    setAppTypeSelect(appTypes) {
      return appTypes.map((typeData) => {
        return { value: typeData.id, text: typeData.name };
      });
    },
    filteringHistoryData() {
      this.historyData = this.dataReset(this.initHistoryData);
      const self = this;
      this.historyData = this.historyData.filter((history) => {
        const searchAppType = self.search.series === '' ? undefined : self.apptypeList.find(({ value }) => value === self.search.series);
        const searchAppTypeText = (typeof searchAppType === 'undefined') ? '' : searchAppType.text;
        const matchingYear = history.year === self.search.year;
        const matchingAppType = searchAppTypeText === '' || history.apptype === searchAppTypeText;
        const matchingStatus = self.search.status === '' || history.changeStatus === parseFloat(self.search.status);
        const matchingChangeStatus = self.search.hasDataChange === '' || history.hasDataChange === parseFloat(self.search.hasDataChange);
        const matchingAppName = self.search.appName === null
          || history.appName.includes(self.search.appName);
        const matchingChangerName = self.search.changerName === null
          || history.changerName.includes(self.search.changerName);
        return matchingYear && matchingAppType
          && matchingStatus && matchingChangeStatus
          && matchingAppName && matchingChangerName;
      });
      this.totalRows = this.historyData.length;
    },
  },
  computed: {
    nowPageCount() {
      if (this.totalRows === 0) {
        return 0;
      }
      let maxPage = Math.floor(this.totalRows / this.perPage);
      // 上記計算にあまりがあれば、ページ数をプラス1
      const mod = this.totalRows % this.perPage;
      let lastPageItemNum;
      if (mod !== 0) {
        maxPage += 1;
        lastPageItemNum = mod;
      } else {
        lastPageItemNum = this.perPage;
      }
      // 最終ページ以外は、現在の表示件数設定の値
      if (this.currentPage < maxPage) {
        return this.perPage;
      }
      // 最終ページが表示件数ぴったりの場合以外は、端数を表示
      return lastPageItemNum;
    },
  },
};
</script>
<style scoped>
  #serchWrap input, #serchWrap select {
    height: 50px;
  }

  #perPageSelect {
    height: 40px;
    width:120px;
  }

  .search_area {
    background: #DDD;
    padding: 10px;
    margin-bottom: 30px;
  }

  .bold {
    font-weight: bold;
  }

  .searchMiddle {
    width: 205px;
  }

  .searchShort {
    width: 135px;
  }

  .searchSpace {
    margin-right: 15px;
  }

  #popover {
    cursor: pointer;
    color: #FFF !important;
    text-decoration: underline;
  }

  .link {
    color: #0A8EA7 !important;
    cursor: pointer;
  }

  .link:hover {
    text-decoration: underline;
  }


</style>
